<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="step-container">
      <a-steps :current="step" size="small" progress-dot>
        <a-step v-if="isCar">
          <template slot="title">
            审核订单是否符合采购标准
          </template>
        </a-step>
        <a-step title="订单核算" />
        <a-step title="审核完成" />
      </a-steps>
    </section>
    <section v-if="step === 0" class="info-container">
      <a-row>
        <a-col>
          <div class="info-title">基本信息</div>
        </a-col>
      </a-row>
      <a-form :form="infoForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单编码" class="form-item">
              {{ info.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型" class="form-item">
              {{ info.orderType }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间" class="form-item">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="经销商" class="form-item">
              {{ info.businessVenuName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否全款" class="form-item">
              {{ info.paymentType }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态" class="form-item">
              {{ info.approveStatus }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="info.orderType === '远期订单'">
          <a-col :span="8">
            <a-form-item label="下订月份" class="form-item">
              {{ formatList(dateList) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="订单备注" class="form-item">
              <div class="form-item__note">
                {{ info.memo ? info.memo : "-" }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section v-if="step === 0" class="receive-info">
      <div class="info-title">收货信息</div>
      <a-form :form="receiveForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名" class="form-item">
              {{ info.consigneeName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话" class="form-item">
              {{ info.consigneeTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址" class="form-item">
              {{ info.consigneeDetailAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section v-if="step !== 2 && isCar" class="commodity-info">
      <div class="commodity-title">
        <div class="info-title">商品信息</div>
        <a-button
          v-if="step === 0 && isCar"
          type="primary"
          @click="setStandardVisible = true"
        >
          查看采购标准
        </a-button>
      </div>
      <section v-if="info.orderType === '即时订单'">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tableColumns"
          :pagination="false"
          :loading="loading"
          row-key="skuId"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div
              v-if="record.skuImages && record.skuImages.length > 0"
              class="img-container"
            >
              <img
                v-if="!record.skuImages[0].thumbUrl"
                :src="imageBaseUrl + record.skuImages[0].fileUrl"
                :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
              <img
                v-else
                :src="imageBaseUrl + record.skuImages[0].thumbUrl"
                :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
            </div>
            <div v-else></div>
          </div>
        </a-table>
        <div v-if="step === 0 && isCar" class="commodity-title">
          <div class="info-title">是否导入拼单列表</div>
        </div>
        <a-radio-group v-if="step === 0 && isCar" v-model="isImportValue">
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </section>
      <section v-if="info.orderType === '远期订单'">
        <div
          v-for="(item, index) in orderSkuList"
          :key="index"
          class="table-item"
        >
          <a-table
            class="commodity-table"
            :data-source="item.skuList"
            :columns="tableColumns"
            :loading="loading"
            row-key="skuId"
            :pagination="false"
          >
            <div
              slot="skuImages"
              v-viewer
              slot-scope="text, record"
              class="img-container"
            >
              <div
                v-if="record.skuImages && record.skuImages.length > 0"
                class="img-container"
              >
                <img
                  v-if="!record.skuImages[0].thumbUrl"
                  :src="imageBaseUrl + record.skuImages[0].fileUrl"
                  :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                  alt="产品图片"
                  class="img-sku"
                />
                <img
                  v-else
                  :src="imageBaseUrl + record.skuImages[0].thumbUrl"
                  :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                  alt="产品图片"
                  class="img-sku"
                />
              </div>
              <div v-else></div>
            </div>
            <template slot="title">
              {{ item.installmentSn }}
            </template>
          </a-table>
          <div v-if="step === 0 && isCar" class="commodity-title">
            <div class="info-title">是否导入拼单列表</div>
          </div>
          <a-radio-group
            v-if="step === 0 && isCar"
            v-model="item.isImportValue"
          >
            <a-radio :value="true">
              是
            </a-radio>
            <a-radio :value="false">
              否
            </a-radio>
          </a-radio-group>
        </div>
      </section>
    </section>
    <section v-if="step === 0 && !isCar" class="commodity-info">
      <div class="commodity-title">
        <div class="info-title">商品信息</div>
        <a-button
          v-if="step === 0 && isCar"
          type="primary"
          @click="setStandardVisible = true"
        >
          查看采购标准
        </a-button>
      </div>
      <section v-if="info.orderType === '即时订单'">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tableColumns"
          :pagination="false"
          :loading="loading"
          row-key="skuId"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div
              v-if="record.skuImages && record.skuImages.length > 0"
              class="img-container"
            >
              <img
                v-if="!record.skuImages[0].thumbUrl"
                :src="imageBaseUrl + record.skuImages[0].fileUrl"
                :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
              <img
                v-else
                :src="imageBaseUrl + record.skuImages[0].thumbUrl"
                :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
            </div>
            <div v-else></div>
          </div>
          <template slot="skuVolume" slot-scope="text, record">
            {{ record.skuVolume * record.purchaseQuantity }}
          </template>
          <template slot="skuWeight" slot-scope="text, record">
            {{ record.skuWeight * record.purchaseQuantity }}
          </template>
        </a-table>
        <div v-if="step === 0 && isCar" class="commodity-title">
          <div class="info-title">是否导入拼单列表</div>
        </div>
        <a-radio-group v-if="step === 0 && isCar" v-model="isImportValue">
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </section>
      <section v-if="info.orderType === '远期订单'">
        <div
          v-for="(item, index) in orderSkuList"
          :key="index"
          class="table-item"
        >
          <a-table
            class="commodity-table"
            :data-source="item.skuList"
            :columns="tableColumns"
            :loading="loading"
            row-key="skuId"
            :pagination="false"
          >
            <div
              slot="skuImages"
              v-viewer
              slot-scope="text, record"
              class="img-container"
            >
              <div
                v-if="record.skuImages && record.skuImages.length > 0"
                class="img-container"
              >
                <img
                  v-if="!record.skuImages[0].thumbUrl"
                  :src="imageBaseUrl + record.skuImages[0].fileUrl"
                  :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                  alt="产品图片"
                  class="img-sku"
                />
                <img
                  v-else
                  :src="imageBaseUrl + record.skuImages[0].thumbUrl"
                  :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
                  alt="产品图片"
                  class="img-sku"
                />
              </div>
              <div v-else></div>
            </div>
            <template slot="title">
              {{ item.installmentSn }}
            </template>
            <template slot="skuVolume" slot-scope="text, record">
              {{ record.skuVolume * record.purchaseQuantity }}
            </template>
            <template slot="skuWeight" slot-scope="text, record">
              {{ record.skuWeight * record.purchaseQuantity }}
            </template>
          </a-table>
          <div v-if="step === 0 && isCar" class="commodity-title">
            <div class="info-title">是否导入拼单列表</div>
          </div>
          <a-radio-group
            v-if="step === 0 && isCar"
            v-model="item.isImportValue"
          >
            <a-radio :value="true">
              是
            </a-radio>
            <a-radio :value="false">
              否
            </a-radio>
          </a-radio-group>
        </div>
      </section>
    </section>
    <section v-if="step === 1 && isCar" class="commodity-info">
      <div class="info-title">订单核算</div>
      <a-row v-if="payWay === '全款支付'">
        <a-col :span="8" class="col">
          <div class="price-title">商品总价：</div>
          <a-input-number
            v-model="price"
            :precision="2"
            :min="0.01"
            class="numberIpt"
            @change="onChange"
          />
        </a-col>
      </a-row>
      <a-row v-if="payWay === '全款支付'" class="row-bottom">
        <a-col :span="8" class="col">
          <div class="price-title">交付周期：</div>
          <a-input v-model="orderCycle" @change="onChangeInput">
            <a-select slot="addonAfter" v-model="dateType" style="width: 80px">
              <a-select-option value="天">
                天
              </a-select-option>
              <a-select-option value="周">
                周
              </a-select-option>
              <a-select-option value="月">
                月
              </a-select-option>
            </a-select>
          </a-input>
        </a-col>
      </a-row>
      <a-form
        v-if="payWay === '分期支付'"
        :form="calculationForm"
        class="advanced-search-form"
      >
        <a-row>
          <a-col :span="8">
            <a-form-item label="首付款">
              <a-input-number
                v-model="firstPay"
                :precision="2"
                :min="0.01"
                class="numberIpt"
                @change="onChange"
              />
            </a-form-item>
            <a-form-item label="交付周期">
              <a-input v-model="orderCycle" @change="onChangeInput">
                <a-select
                  slot="addonAfter"
                  v-model="dateType"
                  style="width: 80px"
                >
                  <a-select-option value="天">
                    天
                  </a-select-option>
                  <a-select-option value="周">
                    周
                  </a-select-option>
                  <a-select-option value="月">
                    月
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-for="(item, index) in orderSkuList" :key="index">
          <a-col :span="8">
            <a-form-item :label="`${toChinesNum(index + 1)}期商品总价`">
              <a-input-number
                v-model="item.eachTotalPrice"
                :precision="2"
                :min="0.01"
                class="numberIpt"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item :label="`${toChinesNum(index + 1)}期尾款`">
              <a-input-number
                v-model="item.eachFinalPrice"
                :precision="2"
                :min="0.01"
                class="numberIpt"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section v-if="step === 0 && !isCar" class="commodity-info">
      <div class="info-title">订单核算</div>
      <a-row v-if="payWay === '全款支付'">
        <a-col :span="8" class="col">
          <div class="price-title">商品总价：</div>
          <a-input-number
            v-model="price"
            :precision="2"
            :min="0.01"
            class="numberIpt"
            @change="onChange"
          />
        </a-col>
      </a-row>
      <a-row v-if="payWay === '全款支付'" class="row-bottom">
        <a-col :span="8" class="col">
          <div class="price-title">交付周期：</div>
          <a-input v-model="orderCycle" @change="onChangeInput">
            <a-select slot="addonAfter" v-model="dateType" style="width: 80px">
              <a-select-option value="天">
                天
              </a-select-option>
              <a-select-option value="周">
                周
              </a-select-option>
              <a-select-option value="月">
                月
              </a-select-option>
            </a-select>
          </a-input>
        </a-col>
      </a-row>
      <a-form
        v-if="payWay === '分期支付'"
        :form="calculationForm"
        class="advanced-search-form"
      >
        <a-row>
          <a-col :span="8">
            <a-form-item label="首付款">
              <a-input-number
                v-model="firstPay"
                :precision="2"
                :min="0.01"
                class="numberIpt"
              />
            </a-form-item>
            <a-form-item label="交付周期">
              <a-input v-model="orderCycle" @change="onChangeInput">
                <a-select
                  slot="addonAfter"
                  v-model="dateType"
                  style="width: 80px"
                >
                  <a-select-option value="天">
                    天
                  </a-select-option>
                  <a-select-option value="周">
                    周
                  </a-select-option>
                  <a-select-option value="月">
                    月
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-for="(item, index) in orderSkuList" :key="index">
          <a-col :span="8">
            <a-form-item :label="`${toChinesNum(index + 1)}期商品总价`">
              <a-input-number
                v-model="item.eachTotalPrice"
                :precision="2"
                :min="0.01"
                class="numberIpt"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item :label="`${toChinesNum(index + 1)}期尾款`">
              <a-input-number
                v-model="item.eachFinalPrice"
                :precision="2"
                :min="0.01"
                class="numberIpt"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section v-if="step === 2 && isCar" class="finish">
      <img src="../../assets/icon_finish.png" alt="完成审核" />
      <span>完成审核！</span>
    </section>
    <section v-if="step === 1 && !isCar" class="finish">
      <img src="../../assets/icon_finish.png" alt="完成审核" />
      <span>完成审核！</span>
    </section>
    <section class="bottom-holder"></section>
    <div v-if="isCar" class="examine-container">
      <div class="button-container">
        <a-button
          v-if="step !== 2"
          type="default"
          class="button-item"
          @click="onOperateFirst"
        >
          {{ firstButton }}
        </a-button>
        <a-button
          v-if="step !== 2"
          type="primary"
          class="button-item"
          html-type="submit"
          @click="onOperateSecond"
        >
          {{ secondButton }}
        </a-button>
      </div>
    </div>
    <div v-if="!isCar && step === 0" class="examine-container">
      <div class="button-container">
        <a-button class="button-item" @click="onReject">
          驳回
        </a-button>
        <a-button
          type="primary"
          class="button-item"
          html-type="submit"
          @click="onPass"
        >
          完成
        </a-button>
      </div>
    </div>
    <!-- 设置驳回弹框 -->
    <a-modal
      :visible="showReject"
      title="驳回"
      ok-text="确定"
      cancel-text="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="rejectForm">
        <a-form-item label="驳回原因：" class="rejectForm">
          <a-textarea v-model="rejectReason"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <!--  设置整车采购标准弹窗  -->
    <a-modal v-model="setStandardVisible" title="整车采购标准" :footer="null">
      <div class="set-standard-container">
        <a-input v-model="purchaseMinVolume" class="input-standard" disabled />
        <div class="unit">m³</div>
        <div class="standard-word">≤ 商品装运总体积 ≤</div>
        <a-input v-model="purchaseMaxVolume" class="input-standard" disabled />
        <div class="unit">m³</div>
      </div>
      <div class="set-standard-container-two">
        <a-input v-model="purchaseMinWeight" class="input-standard" disabled />
        <div class="unit">kg</div>
        <div class="standard-word">≤ 商品总重量 ≤</div>
        <a-input v-model="purchaseMaxWeight" class="input-standard" disabled />
        <div class="unit">kg</div>
      </div>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { approvalOrder, toExamineDetail } from "@/services/OrderService";
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import { formatList, toChinesNum } from "@/components/utils";
import Vue from "vue";
import { Modal } from "ant-design-vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

Vue.prototype.confirm = Modal.confirm;

const tableColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "10%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "25%"
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" },
    width: "10%"
  },
  {
    title: "进货单价",
    dataIndex: "wholeSellPrice",
    width: "10%"
  },
  {
    title: "商品数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "进货总价",
    dataIndex: "allPrice",
    width: "10%"
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "skuVolume",
    width: "12.5%"
  },
  {
    title: "装运总重量（kg）",
    dataIndex: "skuWeight",
    width: "12.5%"
  }
];
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      tsFormat,
      formatList,
      imageBaseUrl,
      toChinesNum,
      infoForm: this.$form.createForm(this),
      receiveForm: this.$form.createForm(this),
      rejectForm: this.$form.createForm(this),
      tblData: [],
      tableColumns,
      pagination: {
        hideOnSinglePage: true
      },
      step: 0,
      loading: false,
      showReject: false,
      rejectReason: "",
      isImportValue: false,
      firstButton: "驳回",
      secondButton: "通过",
      price: "",
      payWay: "",
      calculationForm: this.$form.createForm(this),
      orderSn: this.$route.params.orderSn,
      info: {},
      setStandardVisible: false,
      purchaseMinVolume: "",
      purchaseMaxVolume: "",
      purchaseMinWeight: "",
      purchaseMaxWeight: "",
      dateList: [],
      orderSkuList: [],
      firstPay: "",
      firstPayInfo: {},
      orderCycle: "",
      dateType: "天"
    };
  },
  computed: {
    isCar() {
      return (
        this.info &&
        this.info.installmentSn &&
        this.info.installmentSn.indexOf("整车") > -1
      );
    }
  },
  mounted() {
    this.onToExamineOrderDetail();
  },
  methods: {
    // 查看待审核订单详情
    onToExamineOrderDetail() {
      this.loading = true;
      toExamineDetail(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const data = resp.data.data;
          this.payWay = data.paymentType;
          this.info = data;
          if (data.orderType === "即时订单") {
            this.tblData = data.orderSkuList[0].skuList;
            let totalPrice = 0;
            let num = 0;
            let volume = 0;
            let weight = 0;
            this.tblData.forEach(ele => {
              ele.allPrice = ele.wholeSellPrice;
              ele.wholeSellPrice =
                ele.wholeSellPrice / parseInt(ele.purchaseQuantity);
              totalPrice = totalPrice + ele.allPrice;
              num = num + parseInt(ele.purchaseQuantity);
              volume = volume + parseFloat(ele.packVolume);
              weight = weight + parseFloat(ele.skuWeight);
            });
            const param = {
              skuCode: "合计：",
              allPrice: totalPrice.toFixed(2),
              purchaseQuantity: num,
              skuVolume: volume,
              skuWeight: weight
            };
            this.tblData.push(param);
          }
          if (data.orderType === "远期订单") {
            const orderSkuList = data.orderSkuList;
            this.dateList = [];
            this.orderSkuList = [];
            if (orderSkuList.length > 0) {
              orderSkuList.forEach((item, index) => {
                if (index !== 0) {
                  this.dateList.push(item.installmentSn);
                  this.$set(item, "isImportValue", false);
                  item.eachTotalPrice = "";
                  item.eachFinalPrice = "";
                  let totalPrice = 0;
                  let num = 0;
                  let volume = 0;
                  let weight = 0;
                  if (item.skuList && item.skuList.length > 0) {
                    item.skuList.forEach(ele => {
                      ele.allPrice = ele.wholeSellPrice;
                      ele.wholeSellPrice =
                        ele.wholeSellPrice / parseInt(ele.purchaseQuantity);
                      totalPrice = totalPrice + ele.allPrice;
                      num = num + parseInt(ele.purchaseQuantity);
                      volume = volume + parseFloat(ele.packVolume);
                      weight = weight + parseFloat(ele.skuWeight);
                    });
                    const param = {
                      skuCode: "合计：",
                      skuImages: [],
                      allPrice: totalPrice.toFixed(2),
                      purchaseQuantity: num,
                      skuVolume: volume,
                      skuWeight: weight
                    };
                    item.skuList.push(param);
                  }
                  this.orderSkuList.push(item);
                } else {
                  this.firstPayInfo = item;
                }
              });
            }
          }
          if (data.purchase) {
            this.purchaseMinVolume = data.purchase.purchaseMinVolume;
            this.purchaseMaxVolume = data.purchase.purchaseMaxVolume;
            this.purchaseMinWeight = data.purchase.purchaseMinWeight;
            this.purchaseMaxWeight = data.purchase.purchaseMaxWeight;
          }
        }
      });
    },
    handleCancel() {
      this.showReject = false;
    },
    handleOk() {
      const list = [];
      if (this.info.orderSkuList) {
        this.info.orderSkuList.forEach(item => {
          list.push({
            isShare:
              this.info.orderType === "即时订单"
                ? this.isImportValue
                : this.info.isImportValue,
            orderSn: item.orderSn,
            installmentSn: item.installmentSn
          });
        });
      }
      const params = {
        isAudit: false,
        list: list,
        orderSn: this.info.orderSn,
        orderCycle: this.orderCycle ? this.orderCycle + this.dateType : "",
        dismissReason: this.rejectReason
      };
      params.list = JSON.stringify(params.list);
      approvalOrder(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("完成审核");
          this.step = 2;
          this.showReject = false;
        } else {
          this.$message.error(resp.data.code);
        }
      });
    },
    // 操作第一个按钮
    onOperateFirst() {
      if (this.firstButton === "返回上一步") {
        this.step = this.step - 1;
        if (this.step === 1) {
          this.firstButton = "返回上一步";
        } else if (this.step === 0) {
          this.firstButton = "驳回";
          this.secondButton = "通过";
        }
      } else if (this.firstButton === "驳回") {
        this.showReject = true;
      }
    },
    // 驳回
    onReject() {
      this.showReject = true;
    },
    // 操作第二个按钮
    onOperateSecond() {
      if (this.secondButton === "通过") {
        this.step = 1;
        this.firstButton = "返回上一步";
        this.secondButton = "完成";
      } else if (this.secondButton === "完成") {
        if (this.payWay === "全款支付") {
          if (this.price === "" || this.price === null) {
            this.$message.info("请填写商品总价");
          } else if (this.orderCycle === "") {
            this.$message.info("请填写交付周期");
          } else {
            const list = [];
            if (this.info.orderSkuList) {
              this.info.orderSkuList.forEach(item => {
                list.push({
                  isShare:
                    this.info.orderType === "即时订单"
                      ? this.isImportValue
                      : item.isImportValue,
                  orderSn: item.orderSn,
                  totalPrices: this.price,
                  installmentSn: item.installmentSn,
                  balancePayment: this.price
                });
              });
            }
            const params = {
              isAudit: true,
              list: list,
              orderSn: this.info.orderSn,
              orderCycle: this.orderCycle + this.dateType
            };
            params.list = JSON.stringify(params.list);
            approvalOrder(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("完成审核");
                this.step = 2;
              }
            });
          }
        } else if (this.payWay === "分期支付") {
          let isComplete = false;
          if (this.orderSkuList.length > 0) {
            this.orderSkuList.forEach(item => {
              if (
                item.eachTotalPrice === "" ||
                item.eachFinalPrice === "" ||
                this.firstPay === "" ||
                this.orderCycle === "" ||
                item.eachTotalPrice === null ||
                item.eachFinalPrice === null ||
                this.firstPay === null ||
                this.orderCycle === null
              ) {
                isComplete = true;
              }
            });
            if (isComplete) {
              this.$message.info("请确认是否填写完整");
            } else {
              const list = [];
              list.push({
                balancePayment: this.firstPay,
                installmentSn: this.firstPayInfo.installmentSn,
                isShare: false,
                orderSn: this.firstPayInfo.orderSn,
                totalPrices: this.firstPay
              });
              if (this.orderSkuList.length > 0) {
                this.orderSkuList.forEach(item => {
                  list.push({
                    isShare: item.isImportValue,
                    orderSn: item.orderSn,
                    installmentSn: item.installmentSn,
                    balancePayment: item.eachFinalPrice,
                    totalPrices: item.eachTotalPrice
                  });
                });
              }
              const params = {
                isAudit: true,
                list: list,
                orderSn: this.info.orderSn,
                orderCycle: this.orderCycle + this.dateType
              };
              params.list = JSON.stringify(params.list);
              approvalOrder(params).then(resp => {
                if (resp.data.code === "SUCCESS") {
                  this.$message.success("完成审核");
                  this.step = 2;
                }
              });
            }
          }
        }
      }
    },
    // 通过
    onPass() {
      if (this.payWay === "全款支付") {
        if (this.price === "" || this.price === null) {
          this.$message.info("请填写商品总价");
        } else if (this.orderCycle === "") {
          this.$message.info("请填写交付周期");
        } else {
          const list = [];
          if (this.info.orderSkuList) {
            this.info.orderSkuList.forEach(item => {
              list.push({
                isShare:
                  this.info.orderType === "即时订单"
                    ? this.isImportValue
                    : item.isImportValue,
                orderSn: item.orderSn,
                totalPrices: this.price,
                installmentSn: item.installmentSn,
                balancePayment: this.price
              });
            });
          }
          const params = {
            isAudit: true,
            list: list,
            orderSn: this.info.orderSn,
            orderCycle: this.orderCycle + this.dateType
          };
          params.list = JSON.stringify(params.list);
          approvalOrder(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("完成审核");
              this.step = 1;
            }
          });
        }
      } else if (this.payWay === "分期支付") {
        let isComplete = false;
        if (this.orderSkuList.length > 0) {
          this.orderSkuList.forEach(item => {
            if (
              item.eachTotalPrice === "" ||
              item.eachFinalPrice === "" ||
              this.firstPay === "" ||
              this.orderCycle === "" ||
              item.eachTotalPrice === null ||
              item.eachFinalPrice === null ||
              this.firstPay === null ||
              this.orderCycle === null
            ) {
              isComplete = true;
            }
          });
          if (isComplete) {
            this.$message.info("请确认是否填写完整");
          } else {
            const list = [];
            list.push({
              balancePayment: this.firstPay,
              installmentSn: this.firstPayInfo.installmentSn,
              isShare: false,
              orderSn: this.firstPayInfo.orderSn,
              totalPrices: this.firstPay
            });
            if (this.orderSkuList.length > 0) {
              this.orderSkuList.forEach(item => {
                list.push({
                  isShare: item.isImportValue,
                  orderSn: item.orderSn,
                  installmentSn: item.installmentSn,
                  balancePayment: item.eachFinalPrice,
                  totalPrices: item.eachTotalPrice
                });
              });
            }
            const params = {
              isAudit: true,
              list: list,
              orderSn: this.info.orderSn,
              orderCycle: this.orderCycle + this.dateType
            };
            params.list = JSON.stringify(params.list);
            approvalOrder(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("完成审核");
                this.step = 1;
              }
            });
          }
        }
      }
    },
    onBack() {
      this.$router.push({ name: "ToExamineOrder" });
    },
    onChange() {
      // 强制刷新表单，解决表单输入后不显示问题
      this.$forceUpdate();
    },
    onChangeInput(e) {
      const { value } = e.target;
      const reg = /^[1-9]\d*$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.orderCycle = value;
      } else {
        this.orderCycle = "";
      }
    }
  }
};
</script>

<style scoped>
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.button-container {
  display: flex;
}

.button-item {
  margin-right: 15px;
}

.step-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 10px 25px 10px;
}

/deep/ .ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  min-width: 180px;
}

/deep/ .ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 0 0 90px;
}

/deep/ .ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-left: 87px;
}

.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}

.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.ant-row {
  display: flex !important;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}

/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}

.info-container /deep/ .ant-form-item {
  margin: 0;
}

.receive-info {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px;
}

.commodity-info {
  background-color: white;
  margin: 10px 20px 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px;
}

.commodity-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/.ant-input-number-handler-wrap {
  display: none;
}

.commodity-table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.bottom-holder {
  width: 100%;
  height: 70px;
}

.price-title {
  min-width: 80px;
}

.col {
  display: flex;
  align-items: center;
}

.finish {
  color: #3769ff;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 0;
}

.finish img {
  margin-bottom: 20px;
}

.finish span {
  margin-left: 18px;
}

/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}

/deep/ .ant-form-item-control {
  width: 90%;
}

.img-container {
  width: 100px;
  height: 100px;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.set-standard-container {
  display: flex;
  align-items: flex-end;
}

.input-standard {
  width: 100px;
  margin-right: 10px;
}

.standard-word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.set-standard-container-two {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

.unit {
  width: 20px;
}

.img-sku {
  width: 100px;
  height: 100px;
}

.table-item {
  box-sizing: border-box;
  padding-bottom: 20px;
}

.row-bottom {
  margin-top: 20px;
}
.numberIpt {
  width: 100%;
}
.footer-container {
  display: flex;
}
.add-title {
  width: 15%;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
}
.block {
  width: 35%;
  box-sizing: border-box;
  padding: 0 16px 0 16px;
  margin-right: 16px;
}
.add-price,
.add-num,
.add-volumn,
.add-weight {
  width: 12.5%;
  box-sizing: border-box;
  padding: 0 16px;
}
/deep/ .rejectForm .ant-form-item-label {
  min-width: 80px !important;
}
/deep/ .ant-table-footer {
  padding: 16px 0;
}
</style>
